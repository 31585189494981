





































































import { Component, Vue, Prop } from 'vue-property-decorator';
import SubmissionsBoard from './SubmissionsBoard.vue';
import OneSumissionCard from './OneSubmissionCard.vue';
import Draggable from 'vuedraggable';
import { Submission } from '@/types/submissions';

@Component({
  components: {
    SubmissionsBoard,
    OneSumissionCard,
    Draggable
  }
})
export default class SubmissionsCardBoardsTab extends Vue {
    @Prop(Array) readonly submissions!: Submission[]

    @Prop(Boolean) readonly addingNewSubmission!: boolean

    newSubmission: Submission = {
      text: '',
      address: '',
      priority: 3,
    }

    get newSubmissions() {
      return this.$store.getters['submissions/GET_FILTERED_SUBMISSIONS']('new');
    }

    get toDoSubmissions() {
      return this.$store.getters['submissions/GET_FILTERED_SUBMISSIONS']('todo');
    }

    get doneSubmissions() {
      return this.$store.getters['submissions/GET_FILTERED_SUBMISSIONS']('done');
    }
    
    changeItemDisplay(e) {
      e.item.firstChild.style.display = 'block';
    }

    addSubmission() {
      this.$store.dispatch('submissions/CREATE_SUBMISSION', this.newSubmission);
      this.clearForm();
      this.$emit('abandondAddingSubmission');
    }

    abandondAddingSubmission() {
      this.clearForm();
      this.$emit('abandondAddingSubmission');
    }

    clearForm() {
      this.newSubmission.text = '';
      this.newSubmission.address = '';
      this.newSubmission.priority = 3;
    }

    changePriorityToText(priority: number) {
      // eslint-disable-next-line no-nested-ternary
      return priority === 1 ? 'Wysoki' : priority === 2 ? 'Średni' : 'Niski';
    }
}

