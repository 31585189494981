












import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
})
export default class OneSumissionCard extends Vue {
  @Prop(String) readonly id!: string

  @Prop(Boolean) readonly draggable!: boolean
  
  dragStart(e) {
    const { target } = e;
    
    e.dataTransfer.setData('card_id', target.id);

    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  }
}
