










import { Component, Vue } from 'vue-property-decorator';
import SubmissionsCardBoardsTab from './SubmissionsCardBoardsTab.vue';

@Component({
  components: {
    SubmissionsCardBoardsTab
  }
})
export default class SubmissionsCard extends Vue {  
  addingNewSubmission = false;
}

