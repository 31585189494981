











import { Submission } from '@/types/submissions';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
})
export default class SubmissionsBoard extends Vue {
  @Prop(String) readonly id!: string

  @Prop(Array) readonly submissions!: Submission[]

  @Prop(String) readonly boardName!: string

  drop(e) {
    const card_id = e.dataTransfer.getData('card_id');
    const id = Number(card_id.slice(5));
    this.$store.dispatch('submissions/UPDATE_SELECTED_SUBMISSION_STATUS', { id, newStatus: this.boardName });
  }
}
